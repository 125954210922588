#devbuild {
    width: 100%;
    height: 32px; 
    color: white;
    background-color: var(--signal);
    text-align: center;
    font-size: 0.8em;
}


/* start header */
header {
	background: var(--background);
    position: fixed;
    top: 0;
    transition: background-color .2s ease;
    width: 100%;
    z-index: 10000;
    border-bottom: 1px solid var(--primary) !important;
}

#theme-auto {
  display: none;
}

#theme-changer a {   
  text-decoration: none;
  color: var(--grey) !important;  
}

#theme-changer a:hover {
  border-bottom: none !important;
  filter: drop-shadow(0 0 1px var(--grey))
  drop-shadow(0 0 3px var(--grey));
}

[data-theme='dark'] #theme-changer a {
  color: var(--border-color) !important;
}

[data-theme='dark'] #theme-changer a:hover {
  color: white !important;
  filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5))
  drop-shadow(0 0 4px rgba(255, 255, 255, 0.5))
  drop-shadow(0 0 6px rgba(255, 255, 255, 0.5)) !important;
}

nav {
	padding: 0% 6%;
    display: grid;
    grid-template-columns: max-content 1fr;

}
    nav a {
        border: 0;
        text-decoration: none;
        display: block;
    }

    nav img {
        margin: 10px 0;
    }

.logo {
	float:left;
    width: 75px;
}

.menu {
    width: 100%;
    display: grid;
    align-items: center;
}

.menu ul {
    height: 35px;
    display: flex;
    justify-content: flex-end;
}

    .menu ul li {
        float: right;
        margin-left: 20px;
    }

        .menu ul li#home {
            display: none;
        }

        .menu ul li#close {
            display: none;
            background-position: 4.85em 1em;
            background-repeat: no-repeat;
            border: 0;
            cursor: pointer;
            height: 3em;
            text-align: right;
            position: absolute;
            right: 0.2em;
            top: -0.1em;
            vertical-align: middle;
            width: 7em;
        }

            .menu ul li#close a {
                font-size: 30px;
                border: none;
                padding: 0;
            }

            .menu ul li#close span {
                display: none;
            }

            .menu ul li a{
                display:block;
                text-transform: uppercase;
                letter-spacing: .1em;
                color: var(--primary);
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }

                .menu ul li a:hover,
                .menu ul li a.active {
                    border-bottom: dotted !important;
                    border-width: 1px !important;
                }

                .menu ul li a:hover{
                    color: var(--menu-hover);
                }

.menu a.menuToggle {
    position: absolute;
    right: -1em;
    top: 9px;
    display: none;
    border: none !important;
    font-size: 1.2em;
    color: var(--primary);
}

    .menu a.menuToggle:after {
        background-image: url("../../assets/css/images/bars.svg");
        background-position: right center;
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        height: 3.75em;
        vertical-align: top;
        width: 2em;
    }

    .menu a.menuToggle span {
        display: none;
    }
    
.slider_bg {
    margin-top: 102px;
}

.slider_btn {
    display: flex;
    position: absolute;
    left: 50%; /* Move right 50% of the parent's width */
    transform: translate(-50%, 0%); /* Adjust to center the element */
}

a.button {
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    color: white;
    border: 2px solid white;
    font-size: 0.75em;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin: 10px;
    display: flex; /* Make the anchor a flex container */
    align-items: center; /* Center text vertically */
    justify-content: center;
}

a.button:hover {
    color: var(--primary);
    border-color: var(--primary);
}

@media screen and (max-width: 850px) {
    
    .slider_bg {
        margin-top: 50px !important;
    }

    nav {
        grid-template-columns: 100%;
    }
        nav img {
            height: 30px;
        }

        nav .logo {
            width: 100%;
            text-align:center;
        }

        nav .logo a {
            font-size: 0px;
        }

    .menu a.menuToggle:not(show) {
        display: block;
        border: 0 !important;
    }
    
    .menu ul {
        display: none;
        grid-auto-rows: max-content;
        position: absolute;
        right: 0;
        top: 0;
        padding: 45px 15px 15px 15px !important;
        height: 100vh;
        background-color: var(--primary);
        color: var(--light-grey);
    }

    .menu ul.show {
        display: grid !important;
    }

    .menu ul li {
        padding-top: 10px;
    }
        .menu ul li#home {
            display: inline-block;
        }

        .menu ul li#close {
            display: inline;
        }

            .menu ul li#close a {
                margin-right: 10px;
                border: none !important;
            }

        .menu ul li a{
            font-size: 0.825em;
            padding: 10px 4px;
        }

    .menu ul a {
        display: inline-block !important;
        color: white !important;
    }

}
