.newsletter {
    padding: 50px;
    margin: 20px 100px 0 100px;
    background-color: var(--background);
}

[data-theme='dark'] .grey {
  background-color: var(--grey) !important;
}

[data-theme='dark'] .newsletter {
  background-color: var(--dark-grey) !important;
}

.newsletter-logo {
    width: 200px;
    height: 200px;
    margin: 0 auto 100px auto;
}

.newsletter h4 {
    color: var(--primary);
    text-transform: uppercase;
    text-align: center;
    font-size: 1.4em;
    margin-top: 20px;
}

@media only screen and (max-width: 768px){
    .newsletter {
        margin: 20px 0 0 0;
    }

    .newsletter-footer {
        margin: 0 !important;
    }

    .newsletter h4 {
        font-size: 1.2em;
    }
}

.newsletter-strapline {
    text-align: center;
    font-style: italic;
    margin-bottom: 50px;
}

.newsletter-signature {
    border-top: 2px solid var(--dark-grey);
    margin-top: 40px;
}

.newsletter-signature ul {
    margin-top: 50px;
    text-align: center;
}

.newsletter-signature .icons a {
    color: white;
    background-color: var(--dark-grey);
    border-radius: 50%;
    padding-top: 3px;
    margin: 0 5px;
    text-align: center;
    width: 40px;
    height: 37px;
    display: block;
    font-size: 1.2em;
}

    .newsletter-signature .icons a:hover {
        background-color: white;
        color: var(--dark-grey);
    }

.newsletter-footer {
    background-color: var(--dark-grey);
    padding: 50px 0;
    text-align: center;
    color: white;
    margin: 0 100px;
}

.newsletter-footer img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    padding-bottom: 50px;
}