article.blogpost {  
    margin-top: 20px;
}

.blogpost-header {
     display: flex;
}

.blogpost-date, .blogpost-date-mobile {
    margin: 0 10px;
}


.blogpost-date-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .blogpost-date-mobile {
        display: block;
    }
    .blogpost-date {
        display: none;
    }
}

.blogpost-date, .blogpost-date-mobile, .blogpost-copy {
    font-weight: 200;
    text-transform: uppercase;
}

.blogpost-next {
    margin-right: 20px;
}

#date-select {
    position: absolute;
    background-color: white;
    z-index: 100;
    font-size: 15px;
    text-align: center;
    border-left: 1px solid var(--text-color);
    border-top: 1px solid var(--text-color);
    display: none;
}

#date-select.show{
    display: block !important;
}

.date-month {
    border-bottom: 1px solid var(--text-color);
    border-right: 1px solid var(--text-color);    
    height: 25px;
}

.date-days {
    display: grid;
    grid-template-columns: repeat(7, 25px);
}

.date-days div {
    height: 25px;
    border-bottom: 1px solid var(--text-color);
    border-right: 1px solid var(--text-color);
}

.date-days div:last-child {
    border-right: 1px solid var(--text-color);
}

.date-days div a {
    border: none;
    display: block;
    width: 100%;
    height: 100%;
}

.date-days-blank {
    border-right: none !important;
}

.date-days-blank:last-child {
    border-right: 1px solid var(--text-color) !important;
}