.cookies-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--grey);
    color: var(--text-color);
    padding: 15px;
    text-align: center;
    z-index: 1000;
    transition: max-height 0.5s ease, opacity 1s ease;
    max-height: 100px;
    overflow: hidden;
    opacity: 1;
    border-top: 1px solid var(--border-color);
}

[data-theme='light'] .cookies-bar {
  color: var(--light-grey) !important;
}

.cookies-bar.hidden {
    max-height: 0; 
    opacity: 0;
}

    .cookies-bar p {
        font-size: 0.8em;
        line-height: 1.5em;
    }

    @media only screen and (max-width: 640px){
        .cookies-bar {
            font-size: 0.8em;
            line-height: 1em;
        }
    }